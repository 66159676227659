import { useQuery } from '@tanstack/react-query'

import AuthService from '@/shared/services/auth/auth.service'

export const useProfileData = (refetchInterval?: number) => {
  const {
    isLoading,
    data: userData,
    refetch
  } = useQuery(['get user data'], () => AuthService.getMe(), {
    select: ({ data }) => data,
    refetchInterval: refetchInterval ? refetchInterval : 30000,
    retry: 0,
    onSuccess(data) {
      document.body.setAttribute('aria-id', data.id.toString())
    },
    keepPreviousData: true
  })

  return {
    isLoading,
    userData,
    refetch,
    levelType: userData?.star?.raiting_name_en
  }
}
