import React, { FC, PropsWithChildren, Suspense, useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { dataTab } from '@/app/store/constants'

import { typePage } from '@/pages/main/types'

import { Footer } from '@/widgets/footer'

import { useIsMobile, useQueryParams, useTranslationField } from '@/shared/hooks'
import { usePageViews } from '@/shared/hooks/usePageViews'
import { LinkItemMenuProps } from '@/shared/types/global.types'
import { TValuesLocality } from '@/shared/utils/getMessageLocality'

import Breadcrumbs from '../breadcrumbs'
import ScrollButton from '../buttons/scroll-top'
import { Loader } from '../loader'

import styles from './Layout.module.scss'

const Header = React.lazy(() => import('@/widgets/header'))

type TLayout = typePage & PropsWithChildren

//TODO сильно переписать хедер
const Layout: FC<TLayout> = ({
  children,
  withHeaderCover,
  titlePage,
  descriptionMeta,
  position,
  head_block,
  ad_block,
  reverseAd,
  fullWidth,
  style,
  fullComponent,
  hiddenBreadcrumbs,
  hiddenBreadcrumbsAll,
  extraMenuBlock,
  modifers,
  isCenterBreadcrumbs,
  footerHidden,
  replaceRouteName,
  fullHeight,
  hiddenQueryTab,
  goBackLink,
  titlePosition = 'center',
  menuName,
  customBreadcrumbs
}) => {
  const mod = {
    article: false,
    container: false,
    fullHeight: false,
    slots: false,
    fullWidth: false
  }

  modifers?.forEach(item => {
    mod[item] = true
  })

  const ref = useRef<HTMLDivElement>(null)
  const { getField, getFieldStatic, locale } = useTranslationField()
  const [height, setHeight] = useState<number | null>(null)

  const { isMobile: isTablet } = useIsMobile(1180)

  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const location = useLocation()
  const search = useQueryParams()

  const intl = useIntl()

  const [dataTabMenu, setDataTabMenu] = useState<Array<LinkItemMenuProps>>(dataTab)

  usePageViews(dataTabMenu, setDataTabMenu)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    if (ad_block) {
      const container = ref.current!

      const observer = new MutationObserver(() => {
        setHeight(container.clientHeight)
      })

      if (container) {
        observer.observe(container, { childList: true, subtree: true })
      }

      return () => {
        if (container) {
          observer.disconnect()
        }
      }
    }
  }, [])

  const tabName = search?.tab ? search?.tab + '_route' : null

  useEffect(() => {
    if (titlePage) document.title = intl.formatMessage({ id: titlePage })
    if (descriptionMeta) {
      const dataText = intl.formatMessage({ id: descriptionMeta })
      document.querySelector('meta[name="description"]')!.setAttribute('content', dataText)
    }
  }, [locale, location, titlePage, descriptionMeta])

  return (
    <div
      id='App'
      className={clsx('App', styles.containerApp)}
      onMouseDown={event => {
        // закрытие под меню при за его пределами
        const openSubMenu = document.querySelector('.subMenu.active')
        if (openSubMenu && !openSubMenu.contains(event.target as Node)) {
          openSubMenu.classList.remove('active')
        }
      }}>
      <Suspense fallback={<Loader />}>
        <Header
          dataTabMenu={dataTabMenu}
          setDataTabMenu={setDataTabMenu}
          withCover={withHeaderCover}
        />
      </Suspense>
      {/* //TODO: Отключить чат и аналитику */}
      {/* <Chat /> */}
      <main className={styles.main}>
        {isTablet ? (
          <div className={styles.startLineInfoWrapper}>
            {location?.pathname !== '/' && (
              <>
                <div
                  className={clsx(
                    styles.startLineInfo,
                    extraMenuBlock && styles.row,
                    extraMenuBlock?.actionBlock && styles.left,
                    (extraMenuBlock?.component || isCenterBreadcrumbs) && styles.spaceBetween,
                    titlePosition === 'center' && styles.positionCenter
                  )}>
                  <Breadcrumbs
                    goBack={goBackLink}
                    hiddenBreadcrumbsAll={hiddenBreadcrumbsAll}
                    replaceRouteName={replaceRouteName}
                    customBreadcrumbs={customBreadcrumbs}
                    customNameMobi={getFieldStatic(tabName as TValuesLocality) || menuName}
                  />

                  {/* //TODO скорее всего выпилиться  */}
                  {/* {extraMenuBlock && (
										<>
											<BreadcrumbsExtra
												isTab={!!tabName}
												isOpenMenu={isOpenMenu}
												setIsOpenMenu={setIsOpenMenu}
												navBreadCrumbs={navBreadCrumbs}
											/>
										</>
									)} */}

                  {isCenterBreadcrumbs && <div style={{ width: '22px' }} />}
                  {/* //TODO скорее всего выпилиться  */}
                  {/* {!isOpenMenu && extraMenuBlock?.component && (
										<button
											className={styles.extraMenuButton}
											onClick={() => setIsOpenMenu(true)}
										>
											<img src={extraMenuBlock?.icon} alt={'button-action'} />
										</button>
									)}

									{extraMenuBlock?.actionBlock && extraMenuBlock.actionBlock}

									{isOpenMenu && (
										<>
											<button className={styles.extraMenuButton} />
											<div className={styles.content}>
												{extraMenuBlock?.component}
											</div>
										</>
									)} */}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className='AppMainBlock'>
            {!hiddenBreadcrumbs && (
              <Breadcrumbs
                idName={titlePage}
                hiddenQueryTab={hiddenQueryTab}
                replaceRouteName={replaceRouteName}
                customBreadcrumbs={customBreadcrumbs}
              />
            )}
          </div>
        )}
        {!!head_block && (
          <div className='AppMainBlock' style={isTablet ? { marginTop: 0 } : {}}>
            {head_block}
          </div>
        )}
        {fullComponent && <div className={styles.fullBlock}>{fullComponent}</div>}
        {ad_block && reverseAd && <div className={styles.adBlock}>{ad_block}</div>}
        <div
          className={clsx(
            `AppMainBlock`,
            mod.article && styles.articleWrapper,
            mod.container && styles.layout,
            mod.fullWidth && styles.fullWidth,
            mod.slots && styles.slotsWrapper,
            fullHeight && styles.fullHeight,
            fullWidth && 'fullDisplay'
          )}
          style={style}>
          <div
            className={clsx(
              'App-mainBlock',
              position,
              !ad_block && `fullWidth`,
              ad_block && styles.noneFull
            )}>
            <div className='content' ref={ref} style={{ display: 'flex', flexDirection: 'column' }}>
              {children}
            </div>
          </div>
          {ad_block && !reverseAd && (
            <div
              style={{
                maxHeight: `${height}px`
              }}
              className={styles.adBlock}>
              {ad_block}
            </div>
          )}
        </div>

        <div>
          <ScrollButton />
        </div>
      </main>
      {!footerHidden && <Footer />}
    </div>
  )
}

export default Layout
