import {
  betting_school_image,
  betting_school_image_bg,
  betting_school_image_mobile,
  betting_school_image_mobile_bg,
  blog_image,
  blog_image_bg,
  blog_image_mobile,
  blog_image_mobile_bg,
  experts_image,
  experts_image_bg,
  experts_image_mobile,
  experts_image_mobile_bg,
  games_image,
  games_image_bg,
  games_image_mobile,
  games_image_mobile_bg,
  leaderboard_image,
  leaderboard_image_bg,
  leaderboard_image_mobile,
  leaderboard_image_mobile_bg,
  match_centre_image,
  match_centre_image_bg,
  match_centre_image_mobile,
  match_centre_image_mobile_bg,
  reward_image,
  reward_image_bg,
  reward_image_mobile,
  reward_image_mobile_bg,
  shop_image,
  shop_image_bg,
  shop_image_mobile,
  shop_image_mobile_bg
} from '@/shared/assets/home/img'
import { useTranslationField } from '@/shared/hooks/useTranslationField'

type RoundPlacement = {
  top?: string
  left?: string
  right?: string
  bottom?: string
}

type BGPlacement = {
  minWidth: string
  minHeight: string
  rotate?: string
  top: string
  left: string
}

type HomeElement = {
  id: number
  nameEn: string
  nameCn: string
  descriptionEn: string
  descriptionCn: string
  image: string
  roundPlacement: RoundPlacement
  link?: string
  bg?: string
  bgPlacement?: BGPlacement
  mobileImage: string
  mobileBg: string
}

export const useGetHomePageParams = (id: number) => {
  const { locale } = useTranslationField()
  const homeElementHelper: Record<string, HomeElement> = {
    1: {
      id: 1,
      nameEn: 'Betting School',
      nameCn: '学校',
      descriptionEn:
        'If you’re more of a visual learner, come here to watch the informational videos that we prepared. In School, you will be familiarised with the potential of our site, and learn many things about gaming.',
      descriptionCn:
        '如果您更喜欢视觉学习，可以来这里观看我们准备的信息视频。在博彩学校，您将熟悉我们网站的潜力，并学习到许多游戏知识。',
      image: betting_school_image,
      roundPlacement: {
        top: '-70px',
        left: '-90px'
      },
      link: '/school',
      bg: betting_school_image_bg,
      bgPlacement: {
        minWidth: '1670px',
        minHeight: '1045px',
        top: '-215px',
        left: '-360px'
      },
      mobileBg: betting_school_image_mobile_bg,
      mobileImage: betting_school_image_mobile
    },
    2: {
      id: 2,
      nameEn: 'Experts',
      nameCn: '专家',
      descriptionEn:
        'Every expert is specialised in one type of sport. They come from different backgrounds and give advice based on both their own experience and your preferences. Get to know about each of them by checking their bio.',
      descriptionCn:
        '每位专家都精通一种运动。他们来自不同的背景，会根据自己的经验和您的喜好提供建议。查看专家的主页并了解每个人的情况。',
      image: experts_image,
      roundPlacement: {
        top: '-70px',
        right: '-90px'
      },
      link: '/experts',
      bg: experts_image_bg,
      bgPlacement: {
        minWidth: '1856px',
        minHeight: '1235px',
        top: '-254px',
        left: '-423px'
      },
      mobileImage: experts_image_mobile,
      mobileBg: experts_image_mobile_bg
    },
    3: {
      id: 3,
      nameEn: 'Daily Reward',
      nameCn: '每日奖励',
      descriptionEn:
        'Don’t forget to log in everyday - we will deposit 50 betcoins to your account each time! Coins can be used for playing, buying analytic articles, viewing experts’ forecasts and many more activities.',
      descriptionCn:
        '别忘记每天登录——我们每次都会向您的账户存入50个金币！金币可用于游戏、购买分析文章、查看专家预测以及参与其他活动。',
      image: reward_image,
      roundPlacement: {
        top: '-70px',
        left: '-90px'
      },
      link: '/profile/bonuses',
      bg: reward_image_bg,
      bgPlacement: {
        minWidth: '1635px',
        minHeight: '1163px',
        top: '-170px',
        left: '-400px'
      },
      mobileImage: reward_image_mobile,
      mobileBg: reward_image_mobile_bg
    },
    4: {
      id: 4,
      nameEn: 'Games',
      nameCn: '游戏',
      descriptionEn:
        'We present a large variety of popular online games such as Slot machines, money wheel and poker. You can use betcoins to play here, and exchange your winnings in the Shop.',
      descriptionCn:
        '我们为您提供各种流行的在线游戏，如老虎机、转盘和扑克。您可以在这里使用金币进行游戏，也可以在商店兑换奖金。',
      image: games_image,
      roundPlacement: {
        top: '-70px',
        right: '-90px'
      },
      link: '/games',
      bg: games_image_bg,
      bgPlacement: {
        minWidth: '1800px',
        minHeight: '1493px',
        top: '-380px',
        left: '-659px'
      },
      mobileImage: games_image_mobile,
      mobileBg: games_image_mobile_bg
    },
    5: {
      id: 1,
      nameEn: 'Match Centre',
      nameCn: '比赛',
      descriptionEn:
        'In this section, you will see all the matches in the next two days (?) on which you can put a bet - from football to darts! Choose a type of sport, your favorite league, and trek the results.',
      descriptionCn:
        '在这里，您将看到未来两天内可以投注的所有比赛，大到足球，小到飞镖！选择运动类型、您最喜爱的联赛，并查看比赛结果。',
      image: match_centre_image,
      roundPlacement: {
        bottom: '-110px',
        left: '-90px'
      },
      link: '/sports/match-centre',
      bg: match_centre_image_bg,
      bgPlacement: {
        minWidth: '1586px',
        minHeight: '1572px',
        top: '-422px',
        left: '-229px',
        rotate: '90deg'
      },
      mobileImage: match_centre_image_mobile,
      mobileBg: match_centre_image_mobile_bg
    },
    6: {
      id: 6,
      nameEn: 'Leaderboard',
      nameCn: '排行榜',
      descriptionEn:
        'You will see the rankings of the best players here. The list will be renewed frequently, so you can always track your progress. Continue to play and aim higher!',
      descriptionCn:
        '您将在这里看到最佳玩家的排名。该列表会经常更新，因此您可以随时查看自己的进度。继续努力，勇攀高峰！',
      image: leaderboard_image,
      roundPlacement: {
        top: '-70px',
        left: '-90px'
      },
      link: '/leaderboard',
      bg: leaderboard_image_bg,
      bgPlacement: {
        minWidth: '1899px',
        minHeight: '1311px',
        top: '-180px',
        left: '-645px'
      },
      mobileBg: leaderboard_image_mobile_bg,
      mobileImage: leaderboard_image_mobile
    },
    7: {
      id: 7,
      nameEn: 'Shop',
      nameCn: '商店',
      descriptionEn:
        'Shop is where you can “spend” your earnings. Gift cards, certificates… Every coin you win should be used properly here!',
      descriptionCn:
        '在商店可以“消费”您的收入：礼品卡、礼券…… 您的每次胜利都应该在这里得到合理利用！',
      image: shop_image,
      roundPlacement: {
        top: '-70px',
        right: '-90px'
      },
      link: '/shop',
      bg: shop_image_bg,
      bgPlacement: {
        minWidth: '1571px',
        minHeight: '1052px',
        top: '-239px',
        left: '-315px',
        rotate: '-180deg'
      },
      mobileImage: shop_image_mobile,
      mobileBg: shop_image_mobile_bg
    },
    8: {
      id: 8,
      nameEn: 'Blog',
      nameCn: '博客',
      descriptionEn:
        'Blog is for players who want to grasp a broader understanding of the whole business. You will be learning jargons, strategies, even possible traps.',
      descriptionCn:
        '博客适用于希望对整个业务有更广泛了解的玩家。您将学到行话、策略，甚至潜在的陷阱。',
      image: blog_image,
      roundPlacement: {
        bottom: '-110px',
        left: '-90px'
      },
      link: '/blog',
      bg: blog_image_bg,
      bgPlacement: {
        minWidth: '1698px',
        minHeight: '1145px',
        top: '-419px',
        left: '-400px'
      },
      mobileBg: blog_image_mobile_bg,
      mobileImage: blog_image_mobile
    }
  }

  const getHomeElementName = () => {
    return locale === 'en' ? homeElementHelper[id]?.nameEn : homeElementHelper[id]?.nameCn
  }

  const getHomeElementDescription = () => {
    return locale === 'en'
      ? homeElementHelper[id]?.descriptionEn
      : homeElementHelper[id]?.descriptionCn
  }

  const getHomeElementImage = () => {
    return homeElementHelper[id]?.image
  }

  const getHomeElementMobileImage = () => {
    return homeElementHelper[id]?.mobileImage
  }

  const getHomeElementBGImage = () => {
    return homeElementHelper[id]?.bg
  }

  const getHomeElementBGMobileImage = () => {
    return homeElementHelper[id]?.mobileBg
  }

  const getHomeElementRoundPlacement = () => {
    return homeElementHelper[id]?.roundPlacement
  }

  const getHomeElementBGPlacement = () => {
    return homeElementHelper[id]?.bgPlacement
  }

  const getHomeElementLink = () => {
    return homeElementHelper[id]?.link
  }

  return {
    getHomeElementDescription,
    getHomeElementName,
    getHomeElementImage,
    getHomeElementBGImage,
    getHomeElementBGPlacement,
    getHomeElementRoundPlacement,
    getHomeElementLink,
    getHomeElementBGMobileImage,
    getHomeElementMobileImage
  }
}
