import React, { Suspense, useLayoutEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { useAuthStore } from '@/app/zustand-store'

import { HomeHeader } from '@/widgets/home/home-body/home-header'

import Layout from '@/shared/ui/layout'
import { Loader } from '@/shared/ui/loader'

export function randomInteger(min: number, max: number) {
  let rand = min + Math.random() * (max + 1 - min)
  return Math.floor(rand)
}

export const QuizPage = () => {
  const navigate = useNavigate()
  const { authorized } = useAuthStore()
  const urlQuiz = ['/quiz-care', '/quiz-farm', '/quiz-school']

  useLayoutEffect(() => {
    if (!authorized) {
      window.location.pathname = urlQuiz[randomInteger(0, 2)]
    }
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      <Layout
        title='quiz'
        description='game_description'
        titlePage='game_title'
        descriptionMeta='game_description'
        position='center'
        withHeaderCover
        fullWidth={false}
        hiddenBreadcrumbs>
        <HomeHeader withoutLabel withModal />
      </Layout>
    </Suspense>
  )
}
