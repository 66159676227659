import { Dispatch, FC, useState } from 'react'

import clsx from 'clsx'
import { FaPlus } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'

import { LangChanger } from '@/features/header/ui'
import { useProfileData } from '@/features/profile/api/useProfileData'

import { SocialBlock } from '@/entities/header/social-block'

import { CloseMenuJSx, MenuJSX } from '@/shared/assets/header/svg'
import { avatarIcon } from '@/shared/assets/profile/img'
import { ProfileStarJSX } from '@/shared/assets/profile/svg'
import { useTranslationField } from '@/shared/hooks'
import { LinkItemMenuProps } from '@/shared/types/global.types'
import { Accordion } from '@/shared/ui/accordion'
import { Button } from '@/shared/ui/form'
import { reFormatNum } from '@/shared/utils'

import { Logo } from '../header-logo'

import styles from './MobileHeader.module.scss'

interface IProps {
  showMenu: boolean
  setShowMenu: Dispatch<React.SetStateAction<boolean>>
  dataTabMenu: LinkItemMenuProps[]
}

export const MobileHeaderTop: FC<IProps> = ({ showMenu, setShowMenu, dataTabMenu }) => {
  const [active, setActive] = useState(false)
  const { userData } = useProfileData()
  const { getFieldStatic } = useTranslationField()
  const navigate = useNavigate()

  const closeMenu = () => {
    setActive(false)
  }

  return (
    <>
      <div className={styles.wrapperMobile}>
        <div className={styles.logoMenu}>
          <div
            className={clsx(styles.menu, styles.active)}
            onClick={() => setActive(prev => !prev)}>
            {active ? <CloseMenuJSx /> : <MenuJSX />}
          </div>
          {!active && <Logo />}
        </div>

        {active ? (
          <LangChanger isMobile />
        ) : (
          <div className={styles.avatarMobileWrapper}>
            {userData ? (
              <>
                <Link to={'/profile?tab=deposit-history'}>
                  <div className={styles.wallet}>
                    <div className={styles.plus}>
                      <FaPlus />
                    </div>
                    <div className={styles.count}>₿ {reFormatNum(userData?.wallet)}</div>
                  </div>
                </Link>

                <Link to={'/profile'} className={styles.profile}>
                  <div className={styles.editAvatar}>
                    <img src={avatarIcon} alt='face' />
                    {userData.bonus_is_ready && <ProfileStarJSX />}
                  </div>
                </Link>
                {/* //TODO Без фото
								<Link to={'/profile'} className={styles.profile}>
									<ProfileIconJSX />
								</Link> */}
              </>
            ) : (
              <>
                <Button
                  onClick={() => navigate('/profile')}
                  modifiers={['red', 'ultraSmall', 'textCapitalize']}>
                  {getFieldStatic('login')}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      {active && (
        <div className={styles.menuOpen}>
          {!userData && (
            <div className={styles.loginProfile}>
              <div className={styles.title}>{getFieldStatic('login_account')}</div>
              <div className={styles.text}>{getFieldStatic('login_text')}</div>
              <Button
                onClick={() => navigate('/profile')}
                modifiers={['red', 'ultraSmall', 'textCapitalize']}>
                {getFieldStatic('login')}
              </Button>
            </div>
          )}

          {dataTabMenu.map(item => {
            if (['/profile', '/lang'].includes(item.link)) return null
            return (
              <Accordion
                name={getFieldStatic(item.itemMenu.itemName as any)}
                closeMenu={closeMenu}
                link={!item.itemMenu.subMenu?.length ? item.link : undefined}
                isActive={item.itemMenu.active}>
                <div className={styles.menuList}>
                  {item.itemMenu.subMenu?.map(item => (
                    <Link to={item.link} key={item.itemMenu.itemName}>
                      {getFieldStatic(item.itemMenu.itemName as any)}
                    </Link>
                  ))}
                </div>
              </Accordion>
            )
          })}

          <div className={styles.social}>
            <SocialBlock isMobile />
          </div>
        </div>
      )}
    </>
  )
}
