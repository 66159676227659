import { FC } from 'react'

import { FieldValues, useForm } from 'react-hook-form'

import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { Button, FormWrapper, Input, TextArea } from '@/shared/ui/form'

import styles from './FeedbackForm.module.scss'

export const FeedbackFormComponent: FC = () => {
  const { getFieldStatic } = useTranslationField()
  const { isMobile } = useIsMobile()
  const methods = useForm({
    mode: 'all'
  })

  const {
    formState: { errors }
  } = methods

  const onSubmit = async (data: FieldValues) => {}

  return (
    <>
      <FormWrapper onSubmit={v => onSubmit(v)} methods={methods} className={styles.wrapper}>
        {isMobile ? (
          <>
            <Input
              name='email'
              connect
              containerClassName={styles.inputContainer}
              className={styles.input}
              errorText={errors.email?.message?.toString()}
              placeholder={getFieldStatic('email')}
              isClear
              methods={methods}
              registerConfig={{
                required: getFieldStatic('required_field')
              }}
            />
            <Input
              name='name'
              connect
              containerClassName={styles.inputContainer}
              className={styles.input}
              errorText={errors.name?.message?.toString()}
              placeholder={getFieldStatic('name')}
              isClear
              methods={methods}
              registerConfig={{
                required: getFieldStatic('required_field')
              }}
            />
            <TextArea
              name={'message'}
              className={styles.input}
              containerClassName={styles.inputContainer}
              classNameErrorWrapper={styles.inputError}
              classNameError={styles.error}
              placeholder={getFieldStatic('message')}
              connect
              minRows={16}
              errorText={errors.message?.message?.toString()}
              registerConfig={{
                required: getFieldStatic('required_field')
              }}
            />
            <Button classNameContainer={styles.button} type='submit' modifiers={['red']}>
              {getFieldStatic('send')}
            </Button>
          </>
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.label}>{getFieldStatic('enter_email')}</div>
                <Input
                  name='email'
                  connect
                  className={styles.input}
                  errorText={errors.email?.message?.toString()}
                  placeholder={getFieldStatic('email')}
                  isClear
                  methods={methods}
                  registerConfig={{
                    required: getFieldStatic('required_field')
                  }}
                />
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{getFieldStatic('enter_name')}</div>
                <Input
                  name='name'
                  connect
                  className={styles.input}
                  errorText={errors.name?.message?.toString()}
                  placeholder={getFieldStatic('name')}
                  isClear
                  methods={methods}
                  registerConfig={{
                    required: getFieldStatic('required_field')
                  }}
                />
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.label}>{getFieldStatic('enter_text')}</div>
              <TextArea
                name={'message'}
                className={styles.input}
                classNameErrorWrapper={styles.inputError}
                classNameError={styles.error}
                placeholder={getFieldStatic('message')}
                connect
                minRows={16}
                errorText={errors.message?.message?.toString()}
                registerConfig={{
                  required: getFieldStatic('required_field')
                }}
              />
            </div>
            <Button classNameContainer={styles.button} type='submit' modifiers={['red']}>
              {getFieldStatic('send')}
            </Button>
          </>
        )}
      </FormWrapper>
    </>
  )
}
