import { FC, useState } from 'react'

import clsx from 'clsx'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { Link } from 'react-router-dom'

import { useAuthStore } from '@/app/zustand-store'

import { Auth } from '@/widgets/auth'

import { useProfileData } from '@/features/profile/api'

import { avatarIcon } from '@/shared/assets/profile/img'
import { ProfileStarJSX } from '@/shared/assets/profile/svg'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { useTranslationField } from '@/shared/hooks/useTranslationField'
import { LinkItemMenuProps, TValuesLocality } from '@/shared/types/global.types'
import { IUsers } from '@/shared/types/user.types'
import { reFormatNum } from '@/shared/utils'

import styles from './navMenu.module.scss'

interface IProps {
  data: LinkItemMenuProps
  userData?: IUsers.UserRating
  withCover?: boolean
}

export const NavMenuItem: FC<IProps> = ({ data, userData, withCover }) => {
  const { getFieldStatic } = useTranslationField()
  const { authorized } = useAuthStore()
  const [pageName, setPageName] = useState('')
  const { userData: userInfoData } = useProfileData()
  const [isActiveMenu, setIsActiveMenu] = useState(false)
  const { ref } = useOutsideClick(false, () => setIsActiveMenu(false))
  const isActiveBlock =
    data.itemMenu.active || data?.itemMenu?.subMenu?.find(item => item.itemMenu.active)

  if (!data?.itemMenu?.subMenu) {
    return (
      <Link className={clsx(styles.wrapper, isActiveBlock && styles.linkActive)} to={data.link}>
        <>{getFieldStatic(data.itemMenu.itemName as TValuesLocality)}</>
      </Link>
    )
  }

  return (
    <>
      <div
        ref={ref}
        className={clsx(
          styles.wrapper,
          isActiveBlock && !data.link.includes('/profile') && styles.linkActive,
          data.link.includes('/profile') && styles.profile,
          data.link.includes('/profile') && !authorized && styles.nonAuthorized
        )}
        onClick={() => {
          setIsActiveMenu(prev => !prev)
        }}>
        {!!data.itemMenu.image && (
          <div className={clsx(styles.image, !userData && styles.noBorder)}>
            <div
              className={clsx(
                styles.avatarBlock,
                userInfoData?.bonus_is_ready && styles.avatarBlockReady
              )}>
              <img
                src={userData ? avatarIcon : data.itemMenu.image}
                alt='avatar'
                className={clsx(userData && styles.avatar)}
              />
              {userInfoData?.bonus_is_ready && <ProfileStarJSX />}
            </div>
          </div>
        )}
        <div className={styles.item}>
          <div
            className={clsx(
              data.itemMenu.subMenu && styles.subMenu
              // isActiveMenu && styles.subMenuActive
            )}>
            <div className={styles.itemWrap}>
              {data.link === '/profile' ? (
                authorized ? (
                  data.itemMenu.itemName
                ) : (
                  <>{getFieldStatic('register')}</>
                )
              ) : (
                <>{getFieldStatic(data.itemMenu.itemName as TValuesLocality)}</>
              )}
            </div>
            {!!authorized && data.itemMenu.price !== undefined && (
              <span className={styles.balance}>₿ {reFormatNum(data.itemMenu.price)}</span>
            )}
          </div>

          <div className={styles.icon}>{isActiveMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        </div>
        <div
          aria-activedescendant={data.link}
          className={clsx(
            styles.menuDropdown,
            data.link === '/profile' && styles.profile,
            isActiveMenu && styles.active
          )}>
          {!authorized && data.link === '/profile' ? (
            <>
              <button
                className={styles.element}
                onClick={() => {
                  setPageName('login')
                }}>
                <>{getFieldStatic('login')}</>
              </button>
              <button
                className={styles.element}
                onClick={() => {
                  setPageName('registration')
                }}>
                <>{getFieldStatic('register')}</>
              </button>
            </>
          ) : (
            <>
              {withCover && (
                <div
                  className={styles.cover}
                  onClick={event => {
                    event.stopPropagation()
                    setIsActiveMenu(true)
                  }}
                />
              )}
              {data?.itemMenu?.subMenu?.map((item_sub_menu, index) => (
                <Link
                  className={styles.element}
                  key={index}
                  onClick={() => {
                    setIsActiveMenu(false)
                  }}
                  to={item_sub_menu.link}>
                  <>{getFieldStatic(item_sub_menu.itemMenu.itemName as TValuesLocality)}</>
                </Link>
              ))}
            </>
          )}
        </div>
      </div>
      {!authorized && pageName !== '' ? (
        <div className={styles.routeBlock}>
          <Auth pageName={pageName} setPageName={setPageName} widget />
        </div>
      ) : null}
    </>
  )
}
