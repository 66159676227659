import { FC } from 'react'

import { home_image, home_image_mobile } from '@/shared/assets/home/img'
import {
  useGetScreenResolution,
  useIsMobile,
  useLockedScroll,
  useTranslationField
} from '@/shared/hooks'
import { Modal } from '@/shared/ui/modal'
import { TModalModifers } from '@/shared/ui/modal/modal.type'

import styles from './HomeHeader.module.scss'

type IProps = {
  withoutLabel?: boolean
  withModal?: boolean
}

export const HomeHeader: FC<IProps> = ({ withoutLabel, withModal }) => {
  const { locale, getFieldStatic } = useTranslationField()
  const { isMobile } = useIsMobile(768)

  const scrollTo = () => {
    window.scrollTo(0, isMobile ? 640 : 950)
  }
  const modifers = ['absolute', 'withHeader'] as TModalModifers[]
  const { width } = useGetScreenResolution()

  useLockedScroll(withModal)

  return (
    <div className={styles.wrapper}>
      {withModal && (
        <Modal
          isOpen={true}
          onClose={() => {}}
          className={styles.modal}
          modifers={modifers}
          withoutClose>
          <div className={styles.modaltext}>{getFieldStatic('quiz_went')}</div>
        </Modal>
      )}

      {isMobile ? (
        <>
          <div className={styles.title}>
            {locale === 'en' ? (
              <>
                {getFieldStatic('lebao_title')} <span>{getFieldStatic('lebao')}</span>
              </>
            ) : (
              <>
                <span>{getFieldStatic('lebao')}</span>
                {getFieldStatic('lebao_title')}
              </>
            )}
          </div>
          <div className={styles.image}>
            <img src={home_image_mobile} alt={'image'} />
          </div>
          <div className={styles.description}>{getFieldStatic('lebao_description')}</div>
          <div className={styles.link} onClick={scrollTo}>
            {getFieldStatic('get_started')}
          </div>
          {!withoutLabel && (
            <div className={styles.label}>
              {getFieldStatic('how_to_use')}
              <span>{getFieldStatic('lebao')}</span>
              {getFieldStatic('how_to_use_extended')}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={styles.textBlock}>
            {locale === 'en' ? (
              <div className={styles.title} style={{ marginTop: '58px' }}>
                {getFieldStatic('lebao_title')} <span>{getFieldStatic('lebao')}</span>
              </div>
            ) : (
              <div className={styles.title} style={{ marginTop: '145px' }}>
                <span>{getFieldStatic('lebao')}</span>
                {getFieldStatic('lebao_title')}
              </div>
            )}
            <div className={styles.description}>{getFieldStatic('lebao_description')}</div>
            <div className={styles.link} onClick={scrollTo}>
              {getFieldStatic('get_started')}
            </div>
          </div>
          <div className={styles.image}>
            <img src={width > 1000 ? home_image : home_image_mobile} alt={'image'} />
          </div>
          {!withoutLabel && (
            <div className={styles.label}>
              {getFieldStatic('how_to_use')}
              <span>{getFieldStatic('lebao')}</span>
              {getFieldStatic('how_to_use_extended')}
            </div>
          )}
        </>
      )}
    </div>
  )
}
