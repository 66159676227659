import React, { FC, ReactElement } from 'react'

import clsx from 'clsx'
import { IoIosArrowForward } from 'react-icons/io'

import styles from './tabItem.module.scss'

interface IProps {
  title: string
  onClick: () => void
  icon: ReactElement
  isActive?: boolean
  isLastBlock?: boolean
  isArrow?: boolean
}
export const TabItem: FC<IProps> = ({ icon, onClick, title, isActive, isLastBlock, isArrow }) => {
  return (
    <div
      className={clsx(styles.wrapper, isActive && styles.active, isLastBlock && styles.last)}
      onClick={onClick}>
      <div className={styles.icon}>{icon}</div>
      <span>{title}</span>
      {isArrow && (
        <div className={styles.arrow}>
          <IoIosArrowForward />
        </div>
      )}
    </div>
  )
}
